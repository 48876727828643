var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "resetPasswordModalref",
      attrs: {
        id: "resetPasswordModal",
        title: "Passwort neu setzen",
        "hide-backdrop": "",
        "header-class": "login-header",
        "footer-class": "login-footer",
        "body-class": "login-body"
      },
      on: { shown: _vm.onShow }
    },
    [
      _c("b-form", { ref: "resetPasswordForm", on: { submit: _vm.onSubmit } }, [
        _vm.form.success
          ? _c("div", [
              _c("h1", [_vm._v("Passwort geändert.")]),
              _c("p", [
                _vm._v(
                  "Dein Passwort wurde erfolgreich geändert. Du kannst Dich jetzt einloggen."
                )
              ])
            ])
          : _c(
              "div",
              [
                _c(
                  "b-row",
                  { staticClass: "my-1" },
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "12" } },
                      [
                        _c("label", { attrs: { for: "rpwinput-password" } }, [
                          _vm._v("Neues Passwort")
                        ]),
                        _c("b-form-input", {
                          attrs: {
                            id: "rpwinput-password",
                            type: "password",
                            required: "",
                            "aria-describedby": "invalidPasswordFeedback",
                            state: _vm.form.errors.email ? "invalid" : null,
                            pattern: ".{8,}",
                            title:
                              "Das Passwort muss mindestens 8 Zeichen lang sein!"
                          },
                          model: {
                            value: _vm.form.password,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.form,
                                "password",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.password"
                          }
                        }),
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "invalidPasswordFeedback" } },
                          [
                            _vm.form.errors.password
                              ? _c("span", [
                                  _vm._v(
                                    "Fehler beim Zurücksetzen des Passworts. Bitte versuche den Link nochmals neu anzufordern oder wende dich an unseren "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "mailto:hi@999pi.net"
                                      }
                                    },
                                    [_vm._v("Support")]
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
        !_vm.form.success
          ? _c(
              "div",
              { attrs: { slot: "modal-footer" }, slot: "modal-footer" },
              [
                _c("vue-recaptcha", {
                  ref: "recaptcha",
                  attrs: {
                    size: "invisible",
                    sitekey: "6LcT3nIUAAAAABRmt4tfA2bGZ-sBpAAroORFtdEV"
                  },
                  on: {
                    verify: _vm.changePassword,
                    expired: _vm.onCaptchaExpired
                  }
                }),
                !_vm.loading
                  ? _c(
                      "b-button",
                      {
                        staticClass: "highlightbutton",
                        attrs: { block: "", type: "submit" }
                      },
                      [_vm._v("Passwort speichern")]
                    )
                  : _c(
                      "b-button",
                      {
                        staticClass: "highlightbutton",
                        attrs: { block: "", type: "submit", disabled: "" }
                      },
                      [_c("Spinner", { attrs: { color: "white" } })],
                      1
                    )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }