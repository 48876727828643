<template lang="pug">
    
    b-modal#resetPasswordModal(title="Passwort neu setzen", hide-backdrop=true, header-class='login-header', footer-class='login-footer', body-class='login-body', @shown="onShow", ref="resetPasswordModalref")
      b-form(@submit='onSubmit', ref="resetPasswordForm")
          div(v-if='form.success')
              h1 Passwort geändert.
              p
                  | Dein Passwort wurde erfolgreich geändert. Du kannst Dich jetzt einloggen.
          div(v-else)
              b-row.my-1
                  b-col(sm="12")
                      label(for='rpwinput-password') Neues Passwort
                      b-form-input#rpwinput-password(type="password", required, v-model.trim='form.password',
                      aria-describedby="invalidPasswordFeedback",
                      :state="form.errors.email ? 'invalid' : null", pattern=".{8,}", title="Das Passwort muss mindestens 8 Zeichen lang sein!")
                      b-form-invalid-feedback#invalidPasswordFeedback
                          span(v-if="form.errors.password")
                              | Fehler beim Zurücksetzen des Passworts. Bitte versuche den Link nochmals neu anzufordern oder wende dich an unseren <a href='mailto:hi@999pi.net'>Support</a>


          div(slot="modal-footer", v-if="!form.success")
              vue-recaptcha(ref='recaptcha', @verify="changePassword", @expired='onCaptchaExpired', size='invisible', sitekey='6LcT3nIUAAAAABRmt4tfA2bGZ-sBpAAroORFtdEV')

              b-button.highlightbutton(block=true, type='submit', v-if="!loading") Passwort speichern
              b-button.highlightbutton(block=true, type='submit', disabled, v-else)
                  Spinner(color='white')

</template>

<script>
  import VueRecaptcha from 'vue-recaptcha';
  import api          from '../../shared/api';
  import Spinner from 'vue-spinkit';


  export default {
    name: "ResetPassword",
    components: { VueRecaptcha, Spinner },
    props: ['resetUserId', 'resetToken'],
    data() {
      return {
        loading: false,
        form: {
          password: '',
          success: false,
          hasError: false,
          errors: {
            password: false,
          }
        },

      };
    },
    methods: {

      resetErrors() {
        this.form.errors.email = false;
        this.form.hasError = false;
      },

      resetForm() {
        this.form.email = '';
        this.form.success = false;
      },

      async changePassword(recaptchaToken) {
        try {
          this.loading = true;
          this.resetErrors();
          const createResult = await api.updatePassword(recaptchaToken, this.resetUserId, this.resetToken, this.form.password);

          this.$refs.recaptcha.reset();
          this.loading = false;
          this.form.success = true;
          return true;
        } catch (err) {
          this.loading = false;
          this.$refs.recaptcha.reset();
          if(err.response && err.response.status) {
            this.form.hasError = true;
            this.form.errors['password'] = true;
            return true;
          }
          alert('Leider gibt es gerade ein Problem - bitte versuche es etwas später nochmals!');
        }
      },

      onShow(evt) {
        this.resetForm();
        this.resetErrors();
      },
      onCaptchaExpired() {
        this.$refs.recaptcha.reset();
      },
      onSubmit(evt) {
        evt.preventDefault();
        this.$refs.recaptcha.execute();
      },
    },
  }
</script>

<style scoped>

</style>
